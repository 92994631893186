import * as React from 'react';
import { graphql } from 'gatsby';
import { Seo } from 'gatsby-plugin-wpgraphql-seo';
import { rem } from 'polished';
import styled from 'styled-components';

import { FadeIn } from '~/components/animations';
import { Heading } from '~/components/heading';
import { PageHeading } from '~/components/page-heading';
import { Paragraph } from '~/components/paragraph';
import { getMappedHeading } from '~/core/data-mappers';
import { ReactComponent as Line } from '~/static/svg/line.svg';
import { colors, mediaQuery } from '~/theme';
import { Container } from '~/theme/helpers';

const BoxWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  ${mediaQuery('lg')} {
    align-items: flex-start;
  }

  > *:nth-child(1) {
    margin-left: ${rem(40)};
    margin-right: auto;
    max-width: ${rem(512)};

    ${mediaQuery('xl')} {
      margin-left: 0;
      margin-bottom: ${rem(32)};
    }
  }

  > *:nth-child(2) {
    position: absolute;
  }

  > *:nth-child(3) {
    margin-left: auto;
    max-width: ${rem(512)};

    ${mediaQuery('lg')} {
      margin-left: unset;
    }
  }
`;

const IconWrapper = styled.div`
  margin-bottom: ${rem(8)};

  img {
    width: ${rem(48)};
    height: ${rem(48)};
    margin: 0;
  }
`;

const LineWrapper = styled.div`
  ${mediaQuery('xl')} {
    display: none;
  }
`;

const AboutTemplate = ({ data: { pageData } }) => {
  const pageHeading = getMappedHeading(pageData);

  const aboutFields = Object.values(pageData?.aboutFields);

  return (
    <>
      <Seo post={pageData} />

      <PageHeading
        headingTag="h1"
        description={pageHeading.description}
        title={pageHeading.title}
      />

      <section>
        <Container>
          <BoxWrapper>
            {aboutFields.map((field, index) => (
              <>
                <FadeIn key={field.title}>
                  <IconWrapper>
                    <img
                      src={field.icon.localFile.publicURL}
                      alt={field.icon.altText}
                    />
                  </IconWrapper>
                  <Heading
                    tag="h2"
                    size="h3"
                    spacing="md"
                    color={colors.blue600}
                  >
                    {field.title}
                  </Heading>
                  <Paragraph>{field.description}</Paragraph>
                </FadeIn>
                {index === 0 && (
                  <FadeIn>
                    <LineWrapper>
                      <Line />
                    </LineWrapper>
                  </FadeIn>
                )}
              </>
            ))}
          </BoxWrapper>
        </Container>
      </section>
    </>
  );
};

export const query = graphql`
  query($language: String, $slug: String, $locale: String) {
    pageData: wpPage(slug: { eq: $slug }, locale: { locale: { eq: $locale } }) {
      ...PageHeadingFragment
      ...YoastSiteFragment
      aboutFields {
        aboutUs {
          description
          title
          icon {
            altText
            localFile {
              publicURL
            }
          }
        }
        aboutServices {
          description
          title
          icon {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...TranslationFragment
        }
      }
    }
  }
`;

export default AboutTemplate;
